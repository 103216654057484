@tailwind base;
@tailwind components;
@tailwind utilities;

/*
* For custom colors need to set tailwind.config.js
*/

/*
* Fonts
*/
@layer base {
  /* dichiarazione dei font-face */
}

/*
* HTML tag CSS 
*/

#root {
  min-height: 800px; /* Imposta altezza minima */
}

html,
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
}
/* iframe {
  width: 100% !important;
  height: 100% !important;
} */

#videoContainer > * {
  @apply max-w-full;
}

@layer base {
  html {
    @apply font-primary scroll-smooth;
  }

  body {
    @apply scroll-smooth mx-auto text-base relative max-w-[1920px];
  }

  header {
    @apply bg-primary;
  }

  footer {
    @apply bg-primary;
  }

  /* Heading */
  h1,
  .hh1 {
    @apply text-3xl lg:text-4xl;
  }

  h2,
  .hh2 {
    @apply text-2xl lg:text-3xl;
  }

  h3,
  .hh3 {
    @apply text-xl lg:text-2xl;
  }

  h4,
  .hh4 {
    @apply text-lg lg:text-xl;
  }

  .h {
    @apply space-y-2;
  }

  /* Titolo non commerciale in presenza di doppio titolo*/
  h1.title_m {
    @apply italic hh3;
  }
  h2.title_m {
    @apply italic hh4;
  }
  h3.title_m {
    @apply italic hh4;
  }

  /* Titolo commerciale come h1 */
  .h1.title_c {
    @apply block hh1;
  }
  .h2.title_c {
    @apply block hh2;
  }
  .h3.title_c {
    @apply block hh3;
  }
  .h4.title_c {
    @apply block hh4;
  }

  textarea,
  select {
    @apply !ring-primary focus:!ring-primary focus:!border-primary;
  }
}

/*
* Class CSS  - Custom general component
*/
@layer components {
  /*
    * Customs
    */
  .a-full {
    @apply after:content-[""] after:absolute after:inset-0 after:z-[2];
  }

  /*
    * General Container
    */
  .wrapper {
    @apply w-full py-8 mx-auto;
  }
  .wrapper-b {
    @apply w-full pb-8 mx-auto;
  }

  /*
    * General Screen & Box modules Screen
    */
  .screen,
  .screen_box {
    @apply max-w-full 3xl:max-w-[1920px] mx-auto px-4 relative;
  }

  /*
    * Description below H1
    */
  .main-desc {
    @apply text-sm md:text-base lg:text-lg xl:text-xl;
  }

  /*
    * Aspect-ratio
    */
  .aspect-ratio {
    @apply bg-gray-50;
  }
}
